import { Component, OnInit, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbAccordion, NgbPanel, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-our-people',
  templateUrl: './our-people.component.html',
  styleUrls: ['./our-people.component.css']
})
export class OurPeopleComponent implements OnInit {

  
  memberData = [
    
    {
      profile: "assets/images/client/Henriko Tobing_197308111996031001.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Henriko Tobing, SE., ME.",
      designation: "Koordinator/Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Yanwar_196504221987031001.jpeg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Yanwar, SE., M.Si.",
      designation: "Koordinator/Analis Kebijakan"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Yusuf Setiawan, ST., MM.",
      designation: "Koordinator/Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Yuniarti Tri Suwadji_198506012007122001.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Yuniarti Tri Suwadji, SE., ME., MA.",
      designation: "SubKoordinator/Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Firdausi Nuzula_199006052019022006.JPG",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Firdausi Nuzula, S.Pd., M.Si.",
      designation: "SubKoordinator/Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Faizal Amir P Nasution_198903102019021004.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Faizal Amir P. Nasution., S.Kom., M.BA.",
      designation: "SubKoordinator/Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Maryani_197403071998032002.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Maryani, ST., MT.",
      designation: "SubKoordinator/Analis Kebijakan"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Hadhi Raharja, S.IP.",
      designation: "SubKoordinator/Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Rotua Yossina Warsida_197712302006042001.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Rotua Yossina Warsida, SE., M.Si.",
      designation: "SubKoordinator/Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Fika Afiani Ri_fati Rizki_199709112020122018.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Fika Afiani Rif'ati Rizki, ST.",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Ratna Dewi Wuryandari.197803312006042001.jpeg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ratna Dewi Wuryandari, SE., M.Si.",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Benediktus Heru Dwiwangga_199006252020121017.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Benediktus Heru Dwiwangga, S.Kom.",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Ari Yuliastuti_198207202009122002.jpeg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ari Yuliastuti, SH.",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Sita Agung Trisnantari_19880813 201403 2 005.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Sita Agung Trisnantari, S.IP.",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Ayu Puspita Sari_19900621 201503 2 005.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ayu Puspita Sari, S.Sos.",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Nur Siti Annazah_199506172019022009.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Nur Siti Annazah, SE., M.Si.",
      designation: "Analis Kebijakan"
    },
  ]

  memberData2 = [
    
    {
      profile: "assets/images/client/M. Imam Sonhaji_196601041993031001.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "M. Imam Sonhaji, SE., MT.",
      designation: "Kasubbag TU"
    },
    {
      profile: "assets/images/client/Sukirman_196501311987031001.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Sukirman",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Mira Widyani_196706061988032001.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Mira Widyani",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Nia Handayani_198503112011012017.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Nia Handayani, SE.",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Ficktri Marliana Pratiwi_199311052015032002.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ficktri Marliana Pratiwi, Amd.",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Radinal Hadi_199509102020121016.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Radinal Hadi, Amd.",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/Laksitasya Chrysantiningtyas_199011052014032002.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Laksitasya Chrysantiningtyas, SE.",
      designation: "Analis Kebijakan"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Faizul Iqbal",
      designation: "Staff"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Refaldi Putratama",
      designation: "Staff"
    },
    {
      profile: "assets/images/client/userfemale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Elyviani siska kaban",
      designation: "Staff"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Achmad Allan Arwansyah",
      designation: "Staff"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Roos Arafat Ahmad Yani",
      designation: "Staff"
    },
    {
      profile: "assets/images/client/userfemale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Deavy Indhira Wulandari",
      designation: "Staff"
    },
    {
      profile: "assets/images/client/userfemale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ulfah Agistina",
      designation: "Staff"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "M Salim",
      designation: "Staff"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Mahfudin",
      designation: "Staff"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Munajat Herawan",
      designation: "Staff"
    }
    
  ];
  memberData3 = [
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Andhika Widianto Rahardjo",
      designation: "Asisten Ahli"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Syahdan Abdul Haris Siregar",
      designation: "Asisten Ahli"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Muhammad Fikrie Hazami",
      designation: "Asisten Ahli"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Faiz Muhtadi Billah",
      designation: "Asisten Ahli"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Irkham Syahrul Rozikin",
      designation: "Asisten Ahli"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Muhammad Dio Rhiza Amrizal",
      designation: "Asisten Ahli"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Angga Prasetyo Adi",
      designation: "Asisten Ahli"
    },
    {
      profile: "assets/images/client/usermale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Iqbal Faza Ahmad",
      designation: "Asisten Ahli"
    },
    {
      profile: "assets/images/client/userfemale.png",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Nugroho Habibie",
      designation: "Asisten Ahli"
    },
  ]
  activatedId: string;
  @ViewChild('acc') acc: NgbAccordion;
  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(p => {
      this.activatedId = p['id'];
    });
  }
  scroll(id) {
    // console.log(`scrolling to ${id}`);
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: 'auto', block: 'start' });
  }

  accordionId: string;
  
  ngOnInit():void{
    // this.route.queryParams.subscribe(params => {
    //   const accordionId = params['id'];
    //   if (accordionId) {
    //       this.accordion.toggle(accordionId);
    //   }
    // });
  }

  panelChange(event: NgbPanelChangeEvent){
    console.log('event', event)
  }

}

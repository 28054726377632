import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const APIURL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient) { }
  
    private Headers() {
      let headers = new HttpHeaders();
      // headers = headers.append('Authorization', 'Bearer ' + user?.token);
      // headers = headers.append('Content-Type', 'application/json');
      // headers = headers.append('Access-Control-Allow-Origin', '*');
      const options = {headers: headers};
      return options;
    }

    login(url: string, data: object) {
      return this.http.post(APIURL + url, data);
    }
  
    get(url: string) {
      return this.http.get(APIURL + url, this.Headers());
    }
  
    post(url: string, data: any) {
      return this.http.post(APIURL + url, data, this.Headers());
    }
  
    put(url: string, data: any) {
      return this.http.put(APIURL + url, data, this.Headers());
    }
  
    delete(url: string) {
      return this.http.delete(APIURL + url, this.Headers());
    }

    form(url: string, data: FormData){
      return this.http.post(APIURL + url, data, this.Headers());
    }

    file(url: string): Observable<Blob> {
      return this.http.get(url, { responseType: 'blob' });
    }
}

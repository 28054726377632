<section class="main-slider" id="home">
  <div class="slider single-item">
    <owl-carousel-o id="customer-testi" [options]="customOptions">
      <ng-template carouselSlide *ngFor="let l of publicationPinned?.content">
        
        <!-- Hero Start -->
        <section class="bg-half-custom d-table w-100 bg-light" id="home" style="background:url('assets/images/finance/bg.jpg') top">
          <div class="container">
            <div class="row mt-5 mt-sm-0 align-items-center">
              <div class="col-lg-6 col-md-6">
                <div class="title-heading me-lg-4">
                  <h4 class="display-4 fw-bold mb-3"> {{l.title.slice(0, 150)}}  </h4>
                  <p class="text-muted para-desc mb-0" [innerHTML]="l.abstraction.slice(0, 150) "></p>

                  <div class="mt-4 pt-2">
                    <a routerLink="/publication-details/{{l.slug}}" href="javascript:void(0);"
                      class="btn btn-icon btn-pills btn-soft-primary m-1">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a><span class="fw-bold text-uppercase small align-middle ms-1">Selengkapnya</span>
                  </div>
                </div>
              </div>
              <!--end col-->
              
              <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="bg-white p-2 rounded-md">
                  <img src="{{FILEURL + l.featuredImagePath}}" class="img-fluid mx-auto d-block" alt="">
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end container-->
        </section>
        <!--end section-->
        <!-- Hero End -->
      </ng-template>
    </owl-carousel-o>
  </div>
</section>
<!--end section-->
<!-- Hero End -->

<section class="section">
  <div class="container mt-4 mt-lg-0">
    <div class="row align-items-center mb-4 pb-4">
      <div class="col-md-8">
        <div class="section-title text-md-left">
          <h4 class="mb-4">Working Papers</h4>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0">
        <div class="text-center text-md-end">
          <a routerLink="/publication/Working Papers" class="btn btn-soft-primary">Selengkapnya
            <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
          </a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-publication-policy-paper [policyPaperData]="policyPapers"></app-publication-policy-paper>
  </div>
  <!--end container-->

  <div class="container mt-4 mt-lg-0">
    <div class="row align-items-center mb-4 pb-4">
      <div class="col-md-8">
        <div class="section-title text-md-left">
          <h4 class="mb-4">Current Projects</h4>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0">
        <div class="text-center text-md-end">
          <a routerLink="/publication/Current Projects" class="btn btn-soft-primary">Selengkapnya
            <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
          </a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-publication-policy-paper [policyPaperData]="currentProjects"></app-publication-policy-paper>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center mb-4 pb-4">
      <div class="col-md-8">
        <div class="section-title text-md-left">
          <h4 class="mb-4">Policy Staff Papers</h4>
          <p class="text-muted mb-0 para-desc"><span class="text-primary fw-bold">Policy Staff Papers</span>
            berisi konten publikasi personil Pusbangjak yang telah terpublikasi di Jurnal atau media publikasi lainnya.</p>
        </div>
      </div>
      <div class="col-md-4 mt-4 mt-sm-0">
        <div class="text-center text-md-end">
          <a routerLink="/publication/Policy Staff Papers" class="btn btn-soft-primary">Selengkapnya
            <i-feather name="arrow-right" class="icons"></i-feather>
          </a>
        </div>
      </div>
      <!--end col-->
    <!-- Blog Start -->
    <app-publication-policy-staf-paper [policyStafPaperData]="policyStafPapers"></app-publication-policy-staf-paper>
    <!-- Blog End -->
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- Cta start -->
  <div class="container-fluid mt-100 mt-60">
    <div class="rounded-md shadow-md py-5 position-relative"
      style="background: url('assets/images/3.jpg') center center;">
      <div class="bg-overlay rounded-md"></div>
      <div class="container py-5">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="section-title text-center">
              <h2 class="fw-bold text-white title-dark mb-4 pb-2"><span>Pusbangjaknaker</span> is <br> Bridging Knowledge to Policy
              </h2>
              <a routerLink="/about-us" class="btn btn-primary">Read More
                <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
              </a>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </div>
    <!--end slide-->
  </div>
  <!--end container-->
  <!-- Cta End -->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center mb-4 pb-4">
      <div class="col-md-8">
        <div class="section-title text-md-left">
          <h4 class="mb-4">Pusbangjaknaker Update/News</h4>
          <!--<p class="text-muted mb-0 para-desc">Start working with <span class="text-primary fw-bold">Pusbangjak</span>
            that can provide everything you need to generate
            awareness, drive traffic, connect.</p>-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-news-update [newsUpdateData]="newsUpdates"></app-news-update>
    <div class="col-12 mt-4 pt-2">
      <div class="text-center">
        <a href="javascript:void(0)" class="btn btn-primary">Selengkapnya <i-feather name="arrow-right" class="fea icon-sm">
          </i-feather></a>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- FAQ n Contact End -->


<!-- Back to top -->
<a href="#" [ngxScrollTo]="'#home'" id="back-to-top" class="btn btn-icon btn-primary back-to-top">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DatatablePage } from '../../model/datatable-page.model';
import { ApiService } from '../../services/api.service';

interface news_category{
  name: string;
}

interface news {
  news_category: news_category;
  title: string;
  content: string;
  image_path: string;
};


const APIURL = environment.apiUrl;
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  news: news;
  IMAGEURL = APIURL + '/news/file/';
  datatable: DatatablePage;
  pupularPost: DatatablePage;
  newsCategories: any;

  constructor(private apiService: ApiService) { 
    this.getCategory();
    this.setPage({offset: 0, pageSize: 10});
  }

  ngOnInit(): void {
  }

  /**
   * Blog Data
   */

  setPage(pageInfo){
    const url = '/news/datatable/' + pageInfo.offset + '/' + pageInfo.pageSize;
    const option = {
      searchKey: "",
      direction: 'DESC'
    }
    this.apiService.post(url, option).pipe().subscribe((res: DatatablePage) => {
      this.datatable = res;
      this.getPopular();
    }, error => {
      console.log('error', error);
    });
  }

  getPopular(){
    const url = '/news/popular/0/3';
    const option = {
      searchKey: "",
      direction: 'DESC'
    }
    this.apiService.post(url, option).pipe().subscribe((res: DatatablePage) => {
      this.pupularPost = res;
    }, error => {
      console.log('error', error);
    });
  }

  getCategory(){
    const url = '/news/category/datatable/0/0';
    const option = {
      searchKey: "",
      direction: 'ASC'
    }
    this.apiService.post(url, option).pipe().subscribe((res: any) => {
      this.newsCategories = res.content;
    }, error => {
      console.log('error', error);
    });
  }
}

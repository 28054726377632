import { map } from 'rxjs/internal/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { SwiperOptions } from 'swiper';
import { ApiService } from '../../services/api.service';
import { saveAs } from 'file-saver';
import { DatatablePage } from '../../model/datatable-page.model';

const APIURL = environment.apiUrl;
@Component({
  selector: 'app-publication-details',
  templateUrl: './publication-details.component.html',
  styleUrls: ['./publication-details.component.css']
})
export class PublicationDetailsComponent implements OnInit {

  slug: string;
  detail: any;
  FILEURL = APIURL + '/publication/file/';
  RESUMURL = APIURL + '/publication/resume/';
  PDFURL = APIURL + '/publication/pdf/';
  isStaffPaper: boolean = false;
  related: DatatablePage;
  /***
   * Header button list show
   */
  shopPages = true;



  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private modalService: NgbModal) { 
      this.activatedRoute.params.subscribe(p => {
        this.slug = p['slug'];
        this.getDetails(this.slug);
      });
    }

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    navigation: true,
    pagination: false
  };

  /**
   * Testimonial Slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      900: {
        items: 4
      }
    },
    nav: false
  };

  
  ngOnInit(): void {
  }

	openFullscreen(content) {
		this.modalService.open(content, {size: 'xl',});
	}

  resumeDownload(){
    this.apiService.file(this.RESUMURL +'download/' + this.detail?.slug).toPromise().then(
      res => {
        saveAs(res, this.detail?.resumePdfPath + '.pdf');
      }
    )
  }

  pdfDownload(){
    this.apiService.file(this.PDFURL +'download/' + this.detail?.slug).toPromise().then(
      res => {
        saveAs(res, this.detail?.pdfPath + '.pdf');
      }
    )
  }

  gotolink(){
    window.open(this.detail.sourceLink, '_blank');
  }

  /**
   * onclick Image show
   * @param event image passed
   */
  imageShow(event) {
    const image = event.target.src;
    const expandImg = document.getElementById('expandedImg') as HTMLImageElement;
    expandImg.src = image;
  }

  getDetails(slug: string){
    this.isStaffPaper = false;
    const url = '/publication/detail/' + slug;
    this.apiService.get(url).pipe().subscribe((res: any) => {
      this.detail = res.data;
      this.isStaffPaper = this.detail.publicationCategory.some(o => o.isStaffPaper);
      this.getRelated();
    }, error => {
      console.log('error', error);
    });
  }

  getRelated(){
    const url = '/publication/related/0/4';
    let cat = this.detail?.publicationCategory.map(m => m.name);
    const option = {
      searchKey: "",
      direction: 'DESC',
      categorys: cat
    }
    this.apiService.post(url, option).pipe().subscribe((res: DatatablePage) => {
      this.related = res;
    }, error => {
      console.log('error', error);
    });
  }

}

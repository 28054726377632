import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DatatablePage } from '../../model/datatable-page.model';

const APIURL = environment.apiUrl;
@Component({
  selector: 'app-news-update',
  templateUrl: './news-update.component.html',
  styleUrls: ['./news-update.component.css']
})
export class NewsUpdateComponent implements OnInit {

  FILEURL = APIURL + '/news/file/';

  @Input() newsUpdateData: DatatablePage;

  constructor() { }

  ngOnInit(): void {
  }

}

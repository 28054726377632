<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h2> {{detail?.title}} </h2>
            <ul class="list-unstyled mt-4">
              <li class="list-inline-item h6 date text-muted"><i class="mdi mdi-calendar-check"></i> 
                {{detail?.posted_at | date :'dd MMMM, yyyy'}}
              </li>
            </ul>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                  <li class="breadcrumb-item"><a routerLink="/news">News</a></li>
                  <li class="breadcrumb-item active" aria-current="page">News Detail</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->
  
  <!-- Blog STart -->
  <section class="section">
    <div class="container">
      <div class="row">
        <!-- BLog Start -->
        <div class="col-lg-8 col-md-6">
          <div class="card blog blog-detail border-0 shadow rounded">
            <img *ngIf="detail.imagePath != null" src="{{FILEURL + detail?.imagePath}}" class="img-fluid rounded-top" alt="">
            <div class="card-body content">
              <h6><i class="mdi mdi-tag text-primary me-1"></i>
                <ng-container *ngFor="let l of detail?.tags; let i = index">
                  <ng-container *ngIf="i > 0">, </ng-container>
                  <a href="javscript:void(0)" class="text-primary">{{l.name}}</a>
                </ng-container>
              </h6>
              <div [innerHTML]="detail?.content"></div>
            </div>
          </div>

          <div class="card shadow rounded border-0 mt-4">
            <div class="card-body">
              <h5 class="card-title mb-0">Related News :</h5>
  
              <div class="row">
                <div class="col-lg-6 mt-4 pt-2" *ngFor="let item of related?.content">
                  <div class="card blog rounded border-0 shadow">
                    <div class="position-relative">
                      <img src="{{FILEURL + item.imagePath}}" class="card-img-top rounded-top" alt="...">
                      <div class="overlay rounded-top bg-dark"></div>
                    </div>
                    <div class="card-body content">
                      <h5><a routerLink="/news-details/{{item.slug}}" class="card-title title text-dark">{{item.title}}</a></h5>
                      <div class="post-meta d-flex justify-content-between mt-3">
                        <!-- <ul class="list-unstyled mb-0">
                          <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                                class="uil uil-heart me-1"></i>33</a></li>
                          <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                class="uil uil-comment me-1"></i>08</a></li>
                        </ul> -->
                        <a routerLink="/news-details/{{item.slug}}" class="text-muted readmore">Read More <i
                            class="uil uil-angle-right-b align-middle"></i></a>
                      </div>
                    </div>
                    <div class="author">
                      <small class="text-light user d-block"><i class="uil uil-user"></i> {{item.posted_at | date :'dd-MM-yyyy hh:mm:ss'}}</small>
                      <!-- <small class="text-light date"><i class="uil uil-calendar-alt"></i> 13th August, 2019</small> -->
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
        <!-- BLog End -->
  
        <!-- START SIDEBAR -->
        <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="card border-0 sidebar sticky-bar rounded shadow">
            <div class="card-body">
              <!-- <div class="widget">
                <h5 class="widget-title">Share</h5>
                <ul class="list-unstyled social-icon mb-0 mt-4">
                    <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                        <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
                      </a></li>
                  </ul>
              </div> -->
  
              <!-- RECENT POST -->
              <div class="widget mb-4 pb-2 d-none">
                <h5 class="widget-title">Recent Post</h5>
                <div class="mt-4">
                  <div class="clearfix post-recent">
                    <div class="post-recent-thumb float-start"> <a href="jvascript:void(0)"> <img alt="img"
                          src="assets/images/blog/07.jpg" class="img-fluid rounded"></a></div>
                    <div class="post-recent-content float-start"><a href="jvascript:void(0)">Consultant Business</a><span
                        class="text-muted mt-2">15th June, 2019</span></div>
                  </div>
                  <div class="clearfix post-recent">
                    <div class="post-recent-thumb float-start"> <a href="jvascript:void(0)"> <img alt="img"
                          src="assets/images/blog/08.jpg" class="img-fluid rounded"></a></div>
                    <div class="post-recent-content float-start"><a href="jvascript:void(0)">Look On The Glorious
                        Balance</a> <span class="text-muted mt-2">15th June, 2019</span></div>
                  </div>
                  <div class="clearfix post-recent">
                    <div class="post-recent-thumb float-start"> <a href="jvascript:void(0)"> <img alt="img"
                          src="assets/images/blog/01.jpg" class="img-fluid rounded"></a></div>
                    <div class="post-recent-content float-start"><a href="jvascript:void(0)">Research Financial.</a> <span
                        class="text-muted mt-2">15th June, 2019</span></div>
                  </div>
                </div>
              </div>
              <!-- RECENT POST -->
  
              <!-- TAG CLOUDS -->
              <div class="widget mb-4 pb-2 d-none">
                <h5 class="widget-title">Tags Cloud</h5>
                <div class="tagcloud mt-4">
                  <a href="jvascript:void(0)" class="rounded">Business</a>
                  <a href="jvascript:void(0)" class="rounded">Finance</a>
                  <a href="jvascript:void(0)" class="rounded">Marketing</a>
                  <a href="jvascript:void(0)" class="rounded">Fashion</a>
                  <a href="jvascript:void(0)" class="rounded">Bride</a>
                  <a href="jvascript:void(0)" class="rounded">Lifestyle</a>
                  <a href="jvascript:void(0)" class="rounded">Travel</a>
                  <a href="jvascript:void(0)" class="rounded">Beauty</a>
                  <a href="jvascript:void(0)" class="rounded">Video</a>
                  <a href="jvascript:void(0)" class="rounded">Audio</a>
                </div>
              </div>
              <!-- TAG CLOUDS -->
            </div>
          </div>
        </div>
        <!--end col-->
        <!-- END SIDEBAR -->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Blog End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DatatablePage } from '../../model/datatable-page.model';
import { ApiService } from '../../services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Lightbox } from 'ngx-lightbox';
import { environment } from 'src/environments/environment';

const APIURL = environment.apiUrl;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

/**
 * Index Component
 */
export class IndexComponent implements OnInit {
  navClass = 'nav-light';

  FILEURL = APIURL + '/publication/file/';

  ngOnInit(): void {
    this.getPublicationPinned();
    this.getPolicyPapers();
    this.getPolicyStafPapers();
    this.getNewsUpdates();
    this.getCurrentProjects();
  }
  publicationPinned: DatatablePage;
  policyPapers: DatatablePage;
  currentProjects: DatatablePage;
  policyStafPapers: DatatablePage;
  newsUpdates: DatatablePage;

  getPublicationPinned(){
    const url = '/publication/pinned';
    this.apiService.get(url).pipe().subscribe((res: DatatablePage) => {
      this.publicationPinned = res;
    }, error => {
      console.log('error', error);
    });
  }

  getCurrentProjects(){
    const url = '/publication/datatable/0/3';
    const option = {
      searchKey: 'Current Projects',
      direction: 'DESC'
    }
    this.apiService.post(url, option).pipe().subscribe((res: DatatablePage) => {
      this.currentProjects = res;
    }, error => {
      console.log('error', error);
    });
  }

  getPolicyPapers(){
    const url = '/publication/datatable/0/3';
    const option = {
      searchKey: 'Working Papers',
      direction: 'DESC'
    }
    this.apiService.post(url, option).pipe().subscribe((res: DatatablePage) => {
      this.policyPapers = res;
    }, error => {
      console.log('error', error);
    });
  }

  getPolicyStafPapers(){
    const url = '/publication/datatable/0/3';
    const option = {
      searchKey: 'Policy Staff Papers',
      direction: 'DESC'
    }
    this.apiService.post(url, option).pipe().subscribe((res: DatatablePage) => {
      this.policyStafPapers = res;
    }, error => {
      console.log('error', error);
    });
  }

  getNewsUpdates(){
    const url = '/news/datatable/0/6';
    const option = {
      searchKey: '',
      direction: 'DESC'
    }
    this.apiService.post(url, option).pipe().subscribe((res: DatatablePage) => {
      this.newsUpdates = res;
    }, error => {
      console.log('error', error);
    });
  }
  
  constructor(private modalService: NgbModal, private _lightbox: Lightbox, 
    private apiService: ApiService) { 
    for (let i = 1; i <= 7; i++) {
      const src = '../../../assets/images/book/' + i + '.jpg';
      const caption = 'Image ' + i + ' caption here';
      const thumb = '../../../assets/images/book/' + i + '-thumb.jpg';
      const book = {
         src: src,
         caption: caption,
         thumb: thumb
      };
    }
  }
  
/***
   * nav bg class class set
   */


showNavigationArrows = true;
showNavigationIndicators = false;

/***
 * Testimonial Slider
 */
customOptions: OwlOptions = {
  loop: true,
  mouseDrag: true,
  touchDrag: false,
  pullDrag: false,
  navSpeed: 700,
  navText: ['', ''],
  dots: true,
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 1
    },
    900: {
      items: 1
    }
  },
  nav: false
};
}

<ng-template #modalresume let-modal modal-fullscreen>
	<div class="modal-header">
		<h4 class="modal-title">Resume Preview</h4>
    
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
    <!-- <embed src="http://localhost:8080/api/ld/publication/file/1678189701733" width="800px" height="2100px" /> -->
    <!-- <iframe width="100%" height="100%" src="http://localhost:8080/api/ld/publication/pdf/1678189701733"></iframe> -->
    <!-- <ngx-extended-pdf-viewer  filenameForDownload="'fileName'" [enablePrint]="true" src="http://localhost:8080/api/ld/publication/pdf/1678189701733" useBrowserLocale="true" height="100%">

    </ngx-extended-pdf-viewer> -->
    <pdf-viewer [src]="RESUMURL + detail?.slug" 
              [render-text]="true"
              [original-size]="false"
              style="width: 1090px; height: 900px">
    </pdf-viewer>
	</div>  
	<div class="modal-footer">
		<button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
	</div>
</ng-template>

<ng-template #modalpdf let-modal modal-fullscreen>
	<div class="modal-header">
		<h4 class="modal-title">Full Paper Preview</h4>
    
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
    <!-- <embed src="http://localhost:8080/api/ld/publication/file/1678189701733" width="800px" height="2100px" /> -->
    <!-- <iframe width="100%" height="100%" src="http://localhost:8080/api/ld/publication/pdf/1678189701733"></iframe> -->
    <!-- <ngx-extended-pdf-viewer  filenameForDownload="'fileName'" [enablePrint]="true" src="http://localhost:8080/api/ld/publication/pdf/1678189701733" useBrowserLocale="true" height="100%">

    </ngx-extended-pdf-viewer> -->
    <pdf-viewer [src]="PDFURL + detail?.slug" 
              [render-text]="true"
              [original-size]="false"
              style="width: 1090px; height: 900px">
    </pdf-viewer>
	</div>  
	<div class="modal-footer">
		<button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
	</div>
</ng-template>

<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title"> {{detail?.title}} </h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                  <li class="breadcrumb-item"><a routerLink="/index-shop">Publikasi</a></li>
                  <li class="breadcrumb-item"><a routerLink="/index-shop">Policy Papers</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Detail Publikasi</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!-- Hero End -->
  
  <section class="section pb-0">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-5">
            
          <owl-carousel-o id="customer-testi" [options]="customOptions">
            <ng-template carouselSlide>
              <div class="tiny-slide">
                <div class="tiny-slide"><img *ngIf="detail?.featuredImagePath != null" src="{{FILEURL + detail?.featuredImagePath}}" class="img-fluid rounded"
                    alt=""></div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
        <!--end col-->
  
        <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="section-title ms-md-4">
            <h3 class="title">{{detail?.title}}</h3>
            <div class="alert alert-outline-primary alert-pills" role="alert">
              <span class="badge rounded-pill bg-primary"> {{detail?.year}} </span>
              <span class="alert-content" *ngIf="isStaffPaper"> {{detail?.journalName}}</span> <!--display none jika bukan staffpaper -->
           </div>
            <p class="text-muted" *ngFor="let a of detail?.author; let i = index"><i class="uil uil-user-check">  </i> {{a.name}} </p>
            <h5 class="mt-4 py-2">Abstract:</h5>
            <p class="text-muted" [innerHTML]="detail?.abstraction"></p>
              <a href="javascript: void(0);" [ngxScrollTo]="'#abstrak'" class="text-muted readmore">Selengkapnya <i
                class="uil uil-angle-right-b align-middle"></i></a>
              
  
                  
  
            <div class="row mt-4 pt-2">
              <div class="col-lg-6 col-12">
                <div class="d-flex align-items-center">
                    <span class="badge badge-link rounded-pill {{l.color}}" *ngFor="let l of detail?.publicationCategory"> {{l.name}} </span>
                </div>
                </div>
              <div class="row mt-2 pt-2"></div>
              <!--end col-->
              <h6>
                <i class="mdi mdi-tag text-primary me-1"></i>
                <ng-container *ngFor="let l of detail?.tags; let i = index">
                  <ng-container *ngIf="i > 0">, </ng-container>
                  <a href="javscript:void(0)" class="text-primary">{{l.name}}</a>
                </ng-container>
              </h6>
              <div class="col-lg-6 col-12 mt-4 mt-lg-0">
                <div class="d-flex shop-list align-items-center">
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
  
            <div class="mt-4 pt-2">
              <button [class.d-none] = "isStaffPaper" (click)="openFullscreen(modalresume)" class="btn btn-soft-primary ms-2"><i-feather name="eye" class="icons"></i-feather> Preview Resume</button>
              <button [class.d-none] = "isStaffPaper" (click)="resumeDownload()" class="btn btn-soft-primary ms-2"><i-feather name="download" class="icons"></i-feather> Download Resume</button>
            </div>
            <div class="mt-2 pt-2">
              <button [class.d-none] = "isStaffPaper" (click)="openFullscreen(modalpdf)" class="btn btn-soft-primary ms-2"><i-feather name="eye" class="icons"></i-feather> Preview Full Paper</button>
              <button [class.d-none] = "isStaffPaper" (click)="pdfDownload()" class="btn btn-soft-primary ms-2"><i-feather name="download" class="icons"></i-feather> Download Full Paper</button>
            </div>
            <div class="mt-2 pt-2">
              <button [class.d-none] = "!isStaffPaper"  (click)="gotolink()" class="btn btn-soft-primary ms-2"><i-feather name="corner-up-right" class="icons"></i-feather> Go To Source</button>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
    <div class="container mt-100 mt-60">
      <div class="row">
        <div class="col-12">
          <ul ngbNav #nav="ngbNav"
            class="nav nav-pills shadow flex-column flex-sm-row d-md-inline-flex mb-0 p-1 bg-white rounded position-relative overflow-hidden"
            id="pills-tab" role="tablist">
            <li ngbNavItem class="nav-item m-1">
              <a ngbNavLink class="nav-link py-2 px-5 rounded">
                <div class="text-center" id="abstrak">
                  <h6 class="mb-0">Abstract</h6>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <p class="text-muted mb-0" [innerHTML]="detail?.abstraction"></p>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem class="nav-item m-1">
              <a ngbNavLink class="nav-link py-2 px-5 rounded">
                <div class="text-center">
                  <h6 class="mb-0">Author</h6>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-md-6">
                    <table class="table">
                      <tbody>
                        <tr *ngFor="let a of detail?.author">
                          <td style="width: 100px;">{{a.name}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem class="nav-item m-1">
              <a ngbNavLink class="nav-link py-2 px-5 rounded">
                <div class="text-center">
                  <h6 class="mb-0">References</h6>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <p class="text-muted mb-0" [innerHTML]="detail?.reference"></p>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="col-12 tab-content mt-5"></div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="col-lg-7 mt-4 pt-2">
        <div class="card work-details rounded bg-light border-0">
            <div class="card-body">
                <h5 class="card-title border-bottom pb-3 mb-3"><i class="uil uil-chart-line"></i>  Statistic</h5>
                <dl class="row mb-0">
                  <dt class="col-md-4 col-5"><i class="uil uil-arrow-growth"></i>  View :</dt>
                  <dd class="col-md-8 col-7 text-muted">{{detail?.viewCount}}</dd>

                  <dt class="col-md-4 col-5"><i class="uil uil-file-download"></i>  Download :</dt>
                  <dd class="col-md-8 col-7 text-muted">{{detail?.downloadCount}}</dd>
              </dl>
            </div>
        </div>
      </div>
    </div>

    <!-- <div class="container mt-100 mt-60">
      <div class="row">
        <div class="col-12">
          <h5 class="mb-0">Share</h5>
        </div>
        <ul class="list-unstyled social-icon mb-0 mt-4">
          <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
              <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
            </a></li>
          <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
              <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
            </a></li>
          <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
              <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
            </a></li>
          <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
              <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
            </a></li>
          <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
              <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
            </a></li>
        </ul>
      </div>
    </div> -->
  <!--haha-->
</section>
<section class="bg-light">  
  <div class="container mt-100 mt-60 bg-light">
    <div class="row align-items-center mb-2 mt-4 pt-4 pb-2">
      <div class="col-md-8">
        <div class="section-title text-md-left">
          <h4 class="mb-4">Related Publication</h4>
          
        </div>
      </div>
      <div class="col-md-4 mt-4 mt-sm-0">
        <div class="text-center text-md-end">
          <a routerLink="/publication/Policy Staff Papers" class="btn btn-soft-primary">Lainnya
            <i-feather name="arrow-right" class="icons"></i-feather>
          </a>
        </div>
      </div>
    </div>
    
    <!--end row-->


  <div class="card shadow rounded border-0 mt-4">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-3 mt-4 pt-2" *ngFor="let item of related?.content">
          <div class="card blog rounded border-0 shadow">
            <div class="position-relative">
              <img *ngIf="item?.featuredImagePath != null"  src="{{FILEURL + item?.featuredImagePath}}" class="card-img-top rounded-top" alt="...">
              <div class="overlay rounded-top bg-dark"></div>
            </div>
            <div class="card-body content">
              <h5><a routerLink="/publication-details/{{item.slug}}" class="card-title title text-dark">{{item.title}}</a></h5>
              <div class="post-meta d-flex justify-content-between mt-3">
                <ul class="list-unstyled mb-0">
                </ul>
                <a routerLink="/publication-details/{{item.slug}}" class="text-muted readmore">Read More <i
                    class="uil uil-angle-right-b align-middle"></i></a>
              </div>
            </div>
            <div class="author">
              <small class="text-light user d-block"><i class="uil uil-user"></i>
                <ng-container *ngFor="let a of item.author; let i = index;">
                  <ng-container *ngIf="i > 0">, </ng-container>
                  {{a.name}}
                </ng-container>
                {{item.name}} 
              </small>
              <small class="text-light date"><i class="uil uil-calendar-alt"></i> {{item.posted_at | date :'dd-MM-yyyy hh:mm:ss'}}</small>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
    </div>
      <!--end row-->
  </div>
</div>
  
    <div class="container-fluid mt-100 mt-60 px-0">
    </div>
    <!--end container-->

  <!--end section-->
</section>
  
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> Publications </h4>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                                <li class="breadcrumb-item"><a routerLink="/index-shop">Publikasi</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{category}}</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!-- Hero End -->

<!-- Start Products -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-12">
                <div class="card border-0 sidebar sticky-bar">
                    <div class="card-body p-0">
                        <!-- SEARCH -->
                        <div class="widget">
                            <form role="search" method="get">
                                <div class="input-group mb-3 border rounded">
                                    <input type="text" id="s" name="s" class="form-control border-0"
                                        placeholder="Search Keywords...">
                                    <button type="submit" class="input-group-text bg-white border-0"
                                        id="searchsubmit"><i class="uil uil-search"></i></button>
                                </div>
                            </form>
                        </div>
                        <!-- SEARCH -->

                        <!-- Categories -->
                        <div class="widget mt-4 pt-2">
                            <h5 class="widget-title">Kategori</h5>
                            <ul class="list-unstyled mt-4 mb-0 blog-categories">
                                <li *ngFor="let l of publicationCategories"><a routerLink="/publication/{{l.name}}">{{l.name}}</a></li>
                            </ul>
                        </div>
                        <!-- Categories -->

                        <!-- Top Products -->
                        <div class="widget mt-4 pt-2">
                            <h5 class="widget-title">Popular</h5>
                            <ul class="list-unstyled mt-4 mb-0">
                                <li class="d-flex align-items-center mt-2" *ngFor="let l of popular?.content">
                                    <a routerLink="/publication-details/{{l.slug}}">
                                        <img src="{{FILEURL + l.featuredImagePath}}"
                                            class="img-fluid avatar avatar-small rounded shadow" style="height:auto;"
                                            alt="">
                                    </a>
                                    <div class="flex-1 content ms-3">
                                        <a routerLink="/publication-details/{{l.slug}}" class="text-dark h6">{{l.title.slice(0, 80)}}</a>
                                        <h9 class="text-muted small fst-italic mb-0 mt-1 float-end">{{l.posted_at | date: 'dd-MM-yyy'}}
                                        </h9>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-9 col-md-8 col-12 mt-5 pt-2 mt-sm-0 pt-sm-0">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-7">
                        <div class="section-title">
                            <h5 class="mb-0">Menampilkan 1–15 dari 47 hasil</h5>
                        </div>
                    </div>
                    <!--end col-->

                </div>
                <!--end row-->

                <div class="row">
                    <div class="col-12 mt-4 pt-2" *ngFor="let item of datatable?.content">
                        <div class="card shop-list border-0 shadow position-relative">
                            <ul class="label list-unstyled mb-0">
                                <li *ngFor="let c of item?.publicationCategory"><a href="javascript:void(0)"
                                        class="badge badge-link rounded-pill {{c.color}}">{{c.name}}</a></li>
                            </ul>
                            <div class="row align-items-center g-0">
                                <div class="col-lg-4 col-md-6">
                                    <div class="shop-image position-relative overflow-hidden">
                                        <a routerLink="/publication-details/{{item.slug}}">
                                            <img src="{{FILEURL + item.featuredImagePath}}" class="img-fluid" alt=""></a>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-lg-8 col-md-6">
                                    <div class="card-body content p-4">
                                        <a routerLink="/publication-details/{{item.slug}}" class="text-dark product-name h4">{{item.title}}</a>
                                        <div class="d-lg-flex align-items-center mt-2 mb-3">
                                            <i _ngcontent-tka-c135="" class="uil uil-user-check align-middle"></i>
                                            <h6 class="text-muted small fst-italic mb-0 me-3" *ngFor="let a of item.author; let i = index">
                                                <ng-container *ngIf="i > 0">, </ng-container>{{a.name}}
                                            </h6>
                                        </div>
                                        <div [innerHTML]="item.abstraction.slice(0, 150)"></div>
                                        <div class="post-meta d-flex justify-content-between mt-3">
                                            <ul class="list-unstyled mb-0">
                                              <li class="list-inline-item me-2 mb-0 text-muted view"><i
                                                    class="uil uil-eye me-1"></i>{{item.viewCount}}</li>
                                              <li class="list-inline-item text-muted download"><i
                                                    class="uil uil-file-download me-1"></i>{{item.downloadCount}}</li>
                                            </ul>
                                            <a routerLink="/publication-details/{{item.slug}}" class="text-muted readmore">Read More <i
                                                class="uil uil-angle-right-b align-middle"></i></a>
                                          </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                        <!--end blog post-->
                    </div>
                    <!--end col-->

                    <!-- PAGINATION START -->
                    <div class="col-12 mt-4 pt-2">
                        <ul class="pagination justify-content-center mb-0">
                            <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous"><i class="mdi mdi-arrow-left"></i> Prev</a></li>
                            <ng-container *ngFor="let item of [].constructor( datatable?.totalPages ); let i = index">
                                <li class="page-item {{i == datatable?.number ? 'active':''}}"><a class="page-link"  (click)="setPage({offset: i, pageSize: 10})">{{i + 1}}</a></li>
                            </ng-container>
                            <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next"> Next<i class="mdi mdi-arrow-right"></i></a></li>
                        </ul>
                    </div>
                    <!--end col-->
                    <!-- PAGINATION END -->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Products -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
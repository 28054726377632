import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../services/api.service';
import { DatatablePage } from '../../model/datatable-page.model';

const APIURL = environment.apiUrl;
@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit {
  slug: string;
  detail: any;
  related: DatatablePage;
  FILEURL = APIURL + '/news/file/';

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService) { 
    this.activatedRoute.params.subscribe(p => {
      this.slug = p['slug'];
    });
  }

  ngOnInit(): void {
    this.getDetails(this.slug);
  }

  getDetails(slug: string){
    const url = '/news/detail/' + slug;
    this.apiService.get(url).pipe().subscribe((res: any) => {
      this.detail = res.data;
      this.getRalated(this.detail?.newsCategory?.name)
    }, error => {
      console.log('error', error);
    });
  }

  getRalated(category: string){
    const url = '/news/related/0/2';
    const option = {
      searchKey: "",
      direction: 'DESC',
      categorys: [
        category
      ]
    }
    this.apiService.post(url, option).pipe().subscribe((res: DatatablePage) => {
      this.related = res;
    }, error => {
      console.log('error', error);
    });
  }

}

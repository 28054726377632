import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DatatablePage } from '../../model/datatable-page.model';

const APIURL = environment.apiUrl;
@Component({
  selector: 'app-publication-policy-staf-paper',
  templateUrl: './publication-policy-staf-paper.component.html',
  styleUrls: ['./publication-policy-staf-paper.component.css']
})
export class PublicationPolicyStafPaperComponent implements OnInit {

  FILEURL = APIURL + '/publication/file/';

  @Input() policyStafPaperData: DatatablePage;
  constructor() { }

  ngOnInit(): void {
  }

}

<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title"> Our People </h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Profile</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Our People</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->

  <section class="section">
    <div class="container">
      <div class="row">
        <div class="p-4">
          <div class="faq-content">
            <ngb-accordion #acc="ngbAccordion" [activeIds]="activatedId" closeOthers="true" (panelChange)="panelChange($event)">
              <ngb-panel id="head" cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingone">
                    <h6 class="title mb-0 text-dark"> Head </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="card team text-center border-0" id="acc1">
                    <div class="position-relative">
                      <img src="assets/images/client/Muhyiddin_197309112000031001.png" class="img-fluid avatar avatar-ex-large rounded-circle shadow"
                        alt="">
                      <ul class="list-unstyled team-icon mb-0 mt-4">
                          <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                            <h6>Lihat CV</h6>
                          </a></li>
                      </ul>
                      <!--end icon-->
                    </div>
                    <div class="card-body py-3 px-0 content">
                      <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Muhyiddin, S.Sos., M.Sc., M.S.E.</a></h5>
                      <small class="designation text-muted">Kepala Pusat</small>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="policy-analyst" cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingtwo">
                    <h6 class="title mb-0 text-dark"> Policy Analyst </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent id="acc2">
                  <app-member [memberData]="memberData"></app-member>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="secretariat" cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark"> Secretariat </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <app-member [memberData]="memberData2"></app-member>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="fellowship" cardClass="border-0 rounded shadow mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingfour">
                    <h6 class="title mb-0 text-dark"> Fellowship </h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <app-member [memberData]="memberData3"></app-member>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Team Start -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
<div *ngIf="!hideFooter">
    <div *ngIf="footerVariant == 'bg-light';else execute_block">
        <!-- Footer Start -->
        <footer class="footer bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                            <img src="assets/images/logo-light.png" height="30" alt="">
                        </a>
                        <p class="mt-4 text-muted">Pusat Pengembangan Kebijakan Ketenagakerjaan Kemnaker RI</p>
                        <ul class="list-unstyled social-icon social mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Menu</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/about-us" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    About us</a></li>
                            <li><a routerLink="/our-people" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Our People</a></li>
                            <li><a routerLink="/publication" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    Publication</a></li>
                            <li><a routerLink="/news" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    News</a></li>
                            <li><a routerLink="/news" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    Videos</a></li>
                            <li><a routerLink="/contact-us" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i> Hubungi Kami</a></li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Usefull Links</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a href="https://kemnaker.go.id/" target = "_blank" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    Kemnaker RI</a></li>
                            <li><a href="https://kemnaker.go.id/unit/barenbang" target = "_blank" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Barenbangnaker</a></li>
                            <li><a href="https://jdih.kemnaker.go.id/" target = "_blank" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    JDIH</a></li>
                            <li><a href="https://journals.kemnaker.go.id/index.php/naker" target = "_blank" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    Jurnal Ketenagakerjaan</a></li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">Newsletter</h5>
                        <p class="mt-4 text-muted">Sign up and receive the latest tips via email.</p>

                        <form>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="foot-subscribe foot-white mb-3">
                                        <label class="form-label">Write your email <span
                                                class="text-danger">*</span></label>
                                        <div class="form-icon position-relative">
                                            <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                            <input type="email" name="email" id="emailsubscribe"
                                                class="form-control bg-light border ps-5 rounded"
                                                placeholder="Your email : " required>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-lg-12">
                                    <div class="d-grid">
                                        <input type="submit" id="submitsubscribe" name="send"
                                            class="btn btn-soft-primary" value="Subscribe">
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
        <!-- Footer End -->
    </div>
    <ng-template #execute_block>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                            <img src="assets/images/logo-light.png" height="60" alt="">
                        </a>
                        <p class="mt-4">Pusat Pengembangan Kebijakan Ketenagakerjaan Kemnaker RI</p>
                        <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul>
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Menu</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a routerLink="/about-us" class="text-foot"><i 
                                        class="uil uil-angle-right-b me-1"></i>
                                    About us</a></li>
                            <li><a routerLink="/our-people" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Our People</a></li>
                            <li><a routerLink="/publication" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Publication</a></li>
                            <li><a routerLink="/news" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    News</a></li>
                            <li><a routerLink="/news" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Videos</a></li>
                            <li><a routerLink="/contact-us" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i> Hubungi Kami</a></li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Usefull Links</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li><a href="https://kemnaker.go.id/" target = "_blank" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Kemnaker RI</a></li>
                            <li><a href="https://kemnaker.go.id/unit/barenbang" target = "_blank" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Barenbangnaker</a></li>
                            <li><a href="https://jdih.kemnaker.go.id/" target = "_blank" class="text-foot"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    JDIH</a></li>
                            <li><a href="https://journals.kemnaker.go.id/index.php/naker" target = "_blank" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                    Jurnal Ketenagakerjaan</a></li>
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Address</h5>
                        <p class="mt-4">Pusat Pengembangan Kebijakan Ketenagakerjaan <br>
                        Kementerian Ketenagakerjaan Republik Indonesia <br>
                        Jl. Jendral Gatot Subroto Kav. 51, Daerah Khusus Ibukota; Jakarta Pusat 12750; Jakarta, Indonesia</p>
                        
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
    </ng-template>
</div>
<!--end footer-->
<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="text-sm-start">
                    <p class="mb-0">©
                        <script type="text/javascript" id="www-widgetapi-script"
                            src="https://www.youtube.com/s/player/5d56cf74/www-widgetapi.vflset/www-widgetapi.js"
                            async=""></script>
                        <script id="iframe_api" src="https://www.youtube.com/iframe_api"></script> {{year}}
                        Pusbangjaknaker. Developed with <i class="mdi mdi-heart text-danger"></i> by <a
                            href="mailto:misbakhulakbar@gmail.com" target = "_blank" class="text-reset">Agentive Tech</a>.
                    </p>
                </div>
            </div>
            <!--end col-->
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</footer>
<!--end footer-->
<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/company/aboutus.jpg');">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level title-heading">
            <h1 class="text-white title-dark"> Tentang Kami </h1>
            <p class="text-white-50 para-desc mb-0 mx-auto">Pusat Pengembangan Kebijakan Ketenagakerjaan</p>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">About Us</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!-- Hero End -->
  
  <section class="section">
    <div class="container">
      <div class="row align-items-center" id="counter">
        <div class="col-md-6">
          <img src="assets/images/company/aboutus.png" class="img-fluid" alt="">
        </div>
        <!--end col-->
  
        <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="ms-lg-4">
            <div class="d-flex mb-4">
              <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" [CountTo]="35" [from]="0"
                  [duration]="5"></span>+</span>
              <span class="h6 align-self-end ms-2">Personil</span>
            </div>
            <div class="section-title">
              <h4 class="title mb-4">Overview</h4>
              <p class="text-muted"> <span class="text-primary fw-bold">Pusat Pengembangan Kebijakan Ketenagakerjaan</span> 
                merupakan unit kerja eselon 2 di bawah Badan Perencanaan dan Pengembangan Ketenagakerjaan (Barenbang Naker) Kementerian Ketenagakerjaan Republik Indonesia, yang mempunyai tugas menyelenggarakan penyusunan
                kebijakan teknis, pelaksanaan, serta evaluasi dan pelaporan
                di bidang pengembangan kebijakan ketenagakerjaan.</p>
              <a routerLink="/contact-us" class="btn btn-primary mt-3">Contact us</a>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
    <div class="container mt-5">
      <app-clients-logo></app-clients-logo>
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  
  <section class="section bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="section-title text-center mb-4 pb-2">
            <h6 class="text-primary">Work Process</h6>
            <h4 class="title mb-4">How do we works ?</h4>
            <!--<p class="text-muted para-desc mx-auto mb-0">Start working with <span
                class="text-primary fw-bold">Pusbangjak</span> that can provide everything you need to generate
              awareness, drive traffic, connect.</p>-->
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
  
      <div class="row">
        <div class="col-md-4 mt-4 pt-2">
          <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
            </div>
  
            <div class="card-body">
              <h5 class="text-dark">Planning</h5>
              <p class="text-muted mb-0">Penyusunan kebijakan teknis, rencana dan program
                pengembangan kebijakan di bidang pelatihan vokasi,
                produktivitas, dan penempatan tenaga kerja dan
                perluasan kesempatan kerja, pengawasan
                ketenagakerjaan, keselamatan dan kesehatan kerja,
                dan hubungan industrial dan jaminan sosial tenaga
                kerja, serta evaluasi program prioritas Kementerian dan
                nasional</p>
            </div>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
          <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
            </div>
  
            <div class="card-body">
              <h5 class="text-dark">Developing</h5>
              <p class="text-muted mb-0">Pelaksanaan pengembangan kebijakan pelatihan
                vokasi, produktivitas, dan penempatan tenaga kerja
                dan perluasan kesempatan kerja, pengawasan
                ketenagakerjaan, keselamatan dan kesehatan kerja,
                dan hubungan industrial dan jaminan sosial tenaga
                kerja, serta pelaksanaan evaluasi program prioritas
                Kementerian dan nasional;</p>
            </div>
          </div>
        </div>
        <!--end col-->
  
        <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
          <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
            <div class="icons text-primary text-center mx-auto">
              <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
            </div>
  
            <div class="card-body">
              <h5 class="text-dark">Monitoring, Evaluating, & Reporting</h5>
              <p class="text-muted mb-0">Pemantauan, evaluasi dan pelaporan pengembangan
                kebijakan di bidang pelatihan vokasi, produktivitas,
                dan penempatan tenaga kerja dan perluasan
                kesempatan kerja, pengawasan ketenagakerjaan,
                keselamatan dan kesehatan kerja, dan hubungan
                industrial dan jaminan sosial tenaga kerja, serta
                evaluasi program prioritas Kementerian dan nasional;
                dan</p>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  
  <!-- Start -->
  <section class="section bg-light pt-0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="video-solution-cta position-relative" style="z-index: 1;">
            <div class="position-relative">
              <img src="assets/images/company/pusbangjak.jpeg" class="img-fluid rounded-md shadow-lg" alt="">
              <div class="play-icon">
                <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" class="play-btn video-play-icon">
                  <i class="mdi mdi-play text-primary rounded-circle bg-white shadow-lg"></i>
                </a>
                <ng-template #content let-modal>
                  <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <youtube-player [videoId]="'qI9ERyuRkyA'" height="450" width="780">
                    </youtube-player>
                  </div>
                </ng-template>
              </div>
            </div>
            
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row -->
      <div class="feature-posts-placeholder bg-primary"></div>
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
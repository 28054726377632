<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)">News</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->
  
  <!-- Blog Start -->
  <section class="section">
    <div class="container">
      <div class="row">
        <!-- BLog Start -->
        <div class="col-lg-8 col-md-6">
          <div class="row">
            <div class="col-lg-6 col-md-12 mb-4 pb-2" *ngFor="let data of datatable?.content">
              <div class="card blog rounded border-0 shadow">
                <div class="position-relative">
                  <img src="{{IMAGEURL + data.imagePath}}" class="card-img-top rounded-top" alt="...">
                  <div class="overlay rounded-top bg-dark"></div>
                </div>
                <div class="card-body content">
                  <h5><a routerLink="/news-details/{{data.slug}}" class="card-title title text-dark">{{data.title}}</a>
                  </h5>
                  <div class="post-meta d-flex justify-content-between mt-3">
                    <a routerLink="/news-details/{{data.slug}}" class="text-muted readmore">Read More 
                      <i class="uil uil-angle-right-b align-middle"></i></a>
                  </div>
                </div>
                <div class="author">
                  <small class="text-light date"><i class="uil uil-calendar-alt"></i> 
                      {{data.posted_at | date :'dd-MM-yyyy hh:mm:ss'}}
                  </small>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <!-- PAGINATION START -->
            <div class="col-12">
              <ul class="pagination justify-content-center mb-0">
                <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Previous">Prev</a></li>
                <ng-container *ngFor="let item of [].constructor( datatable?.totalPages ); let i = index">
                  <li class="page-item {{i == datatable?.number ? 'active':''}}"><button class="page-link" (click)="setPage({offset: i, pageSize: 10})">{{i + 1}}</button></li>
                </ng-container>
                <li class="page-item"><a class="page-link" href="javascript:void(0)" aria-label="Next">Next</a></li>
              </ul>
            </div>
            <!--end col-->
            <!-- PAGINATION END -->
          </div>
          <!--end row-->
        </div>
        <!--end col-->
        <!-- BLog End -->
  
        <!-- START SIDEBAR -->
        <div class="col-lg-4 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
          <div class="card border-0 sidebar sticky-bar rounded shadow">
            <div class="card-body">
              <!-- SEARCH -->
              <!-- <div class="widget">
                <form role="search" method="get">
                  <div class="input-group mb-3 border rounded">
                    <input type="text" id="s" name="s" class="form-control border-0" placeholder="Search Keywords...">
                    <button type="submit" class="input-group-text bg-transparent border-0" id="searchsubmit"><i
                        class="uil uil-search"></i></button>
                  </div>
                </form>
              </div> -->
              <!-- SEARCH -->
  
              <!-- Categories -->
              <div class="widget mb-4 pb-2">
                <h5 class="widget-title">Categories</h5>
                <ul class="list-unstyled mt-4 mb-0 blog-categories">
                  <li *ngFor="let l of newsCategories"><a href="jvascript:void(0)">{{l.name}}</a></li>
                </ul>
              </div>
              <!-- Categories -->

              <!-- RECENT POST -->
              <div class="widget mb-4 pb-2">
                <h5 class="widget-title">Popular Post</h5>
                <div class="mt-4">
                  <div class="clearfix post-recent" *ngFor="let item of pupularPost?.content">
                    <div class="post-recent-thumb float-start"> 
                        <img alt="img" [src]="IMAGEURL + item?.imagePath" class="img-fluid rounded">
                    </div>
                    <div class="post-recent-content float-start">
                      <a routerLink="/news-details/{{item.slug}}">{{item.title}}</a>
                      <span class="text-muted mt-2">{{item.posted_at | date: 'dd MMM, yyyy'}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- RECENT POST -->
            </div>
          </div>
        </div>
        <!--end col-->
        <!-- END SIDEBAR -->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Blog End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
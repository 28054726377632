import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DatatablePage } from '../../model/datatable-page.model';
import { ApiService } from '../../services/api.service';

const APIURL = environment.apiUrl;

@Component({
  selector: 'app-publication',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.css']
})
export class PublicationComponent implements OnInit {

  shopPages = true;

  FILEURL = APIURL + '/publication/file/';
  datatable: DatatablePage;
  popular: DatatablePage;
  category: string;
  publicationCategories;

  constructor(
    private activatedRoute: ActivatedRoute, 
    private apiService: ApiService) { 
    this.activatedRoute.params.subscribe(p => {
      this.category = p['category'] == null ? '' : p['category'];
      this.setPage(this.category, {offset: 0, pageSize: 10});
    });
  }

  ngOnInit(): void {
    this.getCategory();
  }

  getCategory(){
    const url = '/publication/category/datatable/0/0';
    const option = {
      searchKey: "",
      direction: "ASC"
    }
    this.apiService.post(url, option).pipe().subscribe((res: any) => {
      this.publicationCategories = res.content;
    }, error => {
      console.log('error', error);
    });
  }

  getPopular(){
    const url = '/publication/popular/0/4';
    const option = {
      searchKey: "",
      direction: 'DESC'
    }
    this.apiService.post(url, option).pipe().subscribe((res: DatatablePage) => {
      this.popular = res;
    }, error => {
      console.log('error', error);
    });
  }

  setPage(search, pageInfo){
    const url = '/publication/datatable/' + pageInfo.offset + '/' + pageInfo.pageSize;
    const option = {
      searchKey:search,
      direction: "DESC"
    }
    this.apiService.post(url, option).pipe().subscribe((res: DatatablePage) => {
      this.datatable = res;
      this.getPopular();
    }, error => {
      console.log('error', error);
    });
  }

}


<div class="row">
    <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2" *ngFor="let data of policyStafPaperData?.content">
      <div class="card blog rounded border-0 shadow overflow-hidden">
        <div class="position-relative">
          <img src="{{FILEURL + data.featuredImagePath}}" class="card-img-top" alt="...">
          <div class="overlay bg-dark"></div>
          <div class="teacher d-flex align-items-center">
            <i class="uil uil-user"></i>
            <div class="ms-2">
              <h6 class="mb-0">
                <a routerLink="/publication-details/{{data.slug}}" class="text-light user">
                  <ng-container *ngFor="let a of data.author; let i = index;">
                    <ng-container *ngIf="i > 0">, </ng-container>
                    {{a.name}}
                  </ng-container>
                </a>
              </h6>
              <!-- <p class="text-light small my-0">Professor</p> -->
            </div>
          </div>
          <div class="course-fee bg-white text-center shadow-lg rounded-circle">
            <h6 class="text-primary fw-bold fee"></h6>
          </div>
        </div>
        <div class="position-relative">
          <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
          </div>
        </div>
        <div class="card-body content">
          <!-- <small><a href="javascript:void(0)" class="text-primary h6">Design</a></small> -->
          <h5 class="mt-2"><a routerLink="/publication-details/{{data.slug}}" class="title text-dark">{{data.title}}</a></h5>
          <p class="text-muted" [innerHTML]="data.abstraction.slice(0, 150)"></p>
          <a routerLink="/publication-details/{{data.slug}}" class="text-primary">Read More <i
              class="uil uil-angle-right-b align-middle"></i></a>
          <ul class="list-unstyled d-flex justify-content-between border-top mt-3 pt-3 mb-0">
            <li class="text-muted small ms-3">
              <i-feather name="eye" class="fea icon-sm text-primary"></i-feather> {{data.viewCount}}
            </li>
          </ul>
        </div>
      </div>
      <!--end card / course-blog-->
    </div>
    <!--end col-->
  </div>